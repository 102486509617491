import { useState } from 'react'
import { useIntl } from '@/intl'

export const useTexts = () => {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    newDriver: translate({ id: 'form.drivers.edit.header.newDriver' }),
    save: translate({ id: 'form.drivers.edit.footer.save' }),
    create: translate({ id: 'form.drivers.edit.footer.create' }),
    cancel: translate({ id: 'form.drivers.edit.footer.cancel' }),
    driverAppTitle: translate({ id: 'form.drivers.driverApp.title' }),
    routeViewPreferences: translate({ id: 'form.drivers.driverApp.routeViewPreferences' }),

    labels: {
      name: translate({ id: 'form.drivers.edit.fields.name.label' }),
      email: translate({ id: 'form.drivers.edit.fields.email.label' }),
      password: translate({ id: 'form.drivers.edit.fields.password.label' }),
      trackDriver: translate({ id: 'form.drivers.edit.fields.trackDriver.label' }),
      gpsDeviceId: translate({ id: 'form.drivers.edit.fields.gpsDeviceId.label' }),
      mobileFutureDaysVisibility: translate({
        id: 'form.drivers.view.labels.mobileFutureDaysVisibility',
      }),
      summarizeFutureMobileRoute: translate({
        id: 'form.drivers.view.labels.summarizeFutureMobileRoute',
      }),
    },
    helpers: {
      trackDriver: translate({ id: 'form.drivers.edit.fields.trackDriver.helper' }),
      summarizeFutureMobileRoute: translate({
        id: 'form.drivers.edit.fields.summarizeFutureMobileRoute.helper',
      }),
    },
    tooltips: {
      noAvailableVirtualDevices: translate({
        id: 'form.drivers.edit.fields.trackDriver.tooltip.noAvailableVirtualDevices',
      }),
    },
    values: {
      mobileFutureDaysVisibility: (
        mobileFutureDaysVisibility: uui.domain.client.rm.MobileFutureDaysVisibility,
      ) => {
        switch (mobileFutureDaysVisibility) {
          case -1:
            return translate({
              id: 'form.drivers.view.values.mobileFutureDaysVisibility.allDays',
            })
          case 3:
            return translate({
              id: 'form.drivers.view.values.mobileFutureDaysVisibility.todayAndNextTwoDays',
            })
          default:
            return mobileFutureDaysVisibility
        }
      },
      summarizeFutureMobileRoute: (summarizeFutureMobileRoute: boolean) => {
        switch (summarizeFutureMobileRoute) {
          case true:
            return translate({ id: 'form.drivers.view.values.summarizeFutureMobileRoute.summary' })
          case false:
            return translate({
              id: 'form.drivers.view.values.summarizeFutureMobileRoute.fullDetails',
            })
        }
      },
    },
  }))

  return api
}
